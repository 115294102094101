import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useBackgroundOffset } from "../../../components/scrollContext/BackgroundOffsetContext";

const useReferralsParallax = (enable?: boolean) => {
  const initialTranslation = useRef((40 * window.visualViewport.height) / 100);
  const lastScrollPosition = useRef(0);

  const backgroundPositionRef = useBackgroundOffset();

  const storeBackgroundOffset = () => {
    if (backgroundPositionRef?.current !== undefined) {
      const backgroundElement = document.getElementById(
        "background"
      ) as HTMLElement;
      const backgroundOffset = Math.abs(
        parseInt(
          backgroundElement.style.backgroundPositionY.replaceAll(/px/g, "")
        )
      );
      backgroundPositionRef.current = backgroundOffset;
    }
  };

  const getScrollDistance = () => {
    const viewportHeight = window.innerHeight;
    const body = document.body;
    const html = document.documentElement;
    const contentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const scrollDistance = Math.max(contentHeight - viewportHeight, 0);
    return scrollDistance;
  };

  useEffect(() => {
    if (!enable) {
      return;
    }
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        const scrollDistance = getScrollDistance();
        const scrollPosition = window.pageYOffset;
        lastScrollPosition.current = scrollPosition;
        const m = initialTranslation.current / scrollDistance;
        const c = initialTranslation.current;
        const translation = -m * scrollPosition + c;
        const imageElement = document.getElementById(
          "backgroundImage"
        ) as HTMLElement;
        console.log("new translation: ", translation);
        imageElement.style.transform =
          "translateY(" + Math.max(translation, 0) + "px)";
      });
    };

    window.addEventListener("scroll", handleScroll);

    const restoreScroll = () => {
      // window.scrollTo(0, lastScrollPosition.current);
    };

    window.addEventListener("resize", restoreScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", restoreScroll);
    };
  }, [backgroundPositionRef]);

  return { storeBackgroundOffset };
};

export default useReferralsParallax;
