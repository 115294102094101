import { BELONG_INSTA_USERNAME } from "../../config/constants";
import { InstagramLogo } from "../svg/InstagramLogo";
import styles from "./Text.module.css";

export function FollowInstaText() {
  const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    fallbackToWeb(event, `https://www.instagram.com/${BELONG_INSTA_USERNAME}/`);
  };

  function fallbackToWeb(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    webURL: string
  ) {
    setTimeout(function () {
      if (!document.hasFocus()) {
        return;
      }

      window.location.href = webURL;
    }, 500);

    window.location.href = `instagram://user?username=${BELONG_INSTA_USERNAME}`;
    event.preventDefault();
  }

  return (
    <div className={styles.instaTextContainer}>
      <p className={styles.instagram}>
        For regular investment education posts, and stories to help you sound
        smart in front of your friends, follow us on Instagram @be.long.co.
      </p>
      <a
        href="instagram://user?username=temilabinjo"
        target="_blank"
        rel="noreferrer"
        style={{ width: "fit-content" }}
        onClick={handleLinkClick}
      >
        <InstagramLogo />
      </a>
    </div>
  );
}
