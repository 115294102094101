import * as React from "react";
import { SVGProps } from "react";

function CrossHair(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8440_1454)">
        <path
          d="M10.7069 4.50127V0H9.2931V4.50127C6.80052 4.81994 4.81994 6.80052 4.50127 9.2931H0V10.7069H4.50127C4.81994 13.1995 6.80052 15.1801 9.2931 15.4987V20H10.7069V15.4987C13.1995 15.1801 15.1801 13.1995 15.4987 10.7069H20V9.2931H15.4987C15.1801 6.80052 13.1995 4.81994 10.7069 4.50127ZM14.0692 9.2931H10.7069V5.93084C12.4187 6.22743 13.7726 7.58128 14.0692 9.2931ZM9.2931 5.93084V9.2931H5.93084C6.22743 7.58128 7.58128 6.22743 9.2931 5.93084ZM5.93084 10.7069H9.2931V14.0692C7.58128 13.7726 6.22743 12.4187 5.93084 10.7069ZM10.7069 14.0692V10.7069H14.0692C13.7726 12.4187 12.4187 13.7726 10.7069 14.0692Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8440_1454">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CrossHair;
