import { Header } from "./Header";
import { Footer } from "./Footer";

const Privacy = () => (
  <div className="App">
    <Header text={'Privacy Notice for the Belong App'} />
      <div className="content-container">

      <h3>Who we are</h3>
      <p>
Belong is an app operated by Avione Saving & Investment Ltd ('we', 'our', 'us'). We are registered in England and Wales under company number 12873469 and have our registered office at Camburgh House, 27 New Dover Road, Canterbury, CT1 3DN.
</p>
<p>
We are registered with the UK data protection authority (the Information Commissioner's Office or ICO) under number ZB189039.
</p>
<p>
This privacy notice describes how we collect and process personal information about you when you use the Belong app or services, how we use and protect this information, and your rights in relation to this information.
</p>
<p>
This privacy notice applies to all personal information we collect or process about you. “Personal information” is defined as any information relating to an identified or identifiable natural person.      
</p>
<h3>What we do</h3>
<p>
We are registered with the UK Financial Conduct Authority (FCA) under FRN971870. We are authorised to carry out regulated investing activities as an Appointed Representative of Risksave Technologies Ltd FRN775330.

We are authorised to carry out regulated lending and credit activities as an Appointed Representative of Creative Finance Corp Ltd FRN702435. As a credit broker we may introduce you to a panel of lenders to provide the optional Boost loan feature.

We are authorised to carry out regulated payments activities as an Appointed Representative of Bud Financial Ltd FRN793327.
</p>


<h3>Contact Us</h3>

<p>
Avione Saving & Investment Ltd is the controller responsible for the personal information we collect and process. 
</p>
<p>
If you have questions or concerns regarding the way in which your personal information has been used. You can contact us in the following ways: 
</p>

    <ul>
        <li>Using the in-app chat.</li>
        <li>Email us at <a href="mailto:support@be-long.co">support@be-long.co</a></li>
        <li>For data queries email <a href="mailto:data@be-long.co">data@be-long.co</a></li>
        <li>Write to us Belong, Avione Saving and Investment Ltd, Head of Compliance, Camburgh House, 27 New Dover Road, Canterbury, CT1 3DN.</li>
    </ul>

<p>
We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy. If you're still not happy, you can refer your complaint to the UK's supervisory authority: the Information Commissioner's Office (ICO). For more details, you can visit their website at ico.org.uk. We are registered with the ICO, registration no. ZB189039.
</p>

<h3>Personal information we use</h3>
<p>
We will collect personal information about you from a variety of sources, including information we collect from you directly (e.g. when you contact us), and information we collect about you from other sources.
</p>
<p>
We may be required by law to collect certain personal information about you, or as a consequence of any contractual relationship we have with you. Failure to provide this information may prevent or delay our ability to provide our services to you.
</p>

<h3>Information we collect directly from you</h3>
<p>The categories of information that we collect directly from you are:</p>

    <ul>
        <li>personal details (e.g. name, date and place of birth) </li>        
        <li>contact details (e.g. phone number, email address, postal address, mobile number)</li>
        <li>information about your identity (e.g. ID documents, a short video of yourself)</li>
        <li>information about your right to live in the UK and your tax residency</li>
        <li>financial details (e.g. employment status, annual income, number of dependants, monthly expenses)</li>
        <li>answers you give to surveys about the Belong app and our services</li>
    </ul>


<h3>Information we collect or generate when you use the Belong app</h3>
<p>
The categories of information that we collect when you use the Belong app are: 
</p>

    <ul>
        <li>details about how you use the Belong app.</li>
        <li>information you provide in customer service chats on the Belong app.</li>
    </ul>



<h3>Information we collect from your phone</h3>
<p>The categories of information that we collect from your phone are: </p>

    <ul>
        <li>IP address and device ID.</li>
        <li>mobile network and operating system.</li>
        <li>device model.</li>
    </ul>


<h3>Information we collect from other sources</h3>
<p>We collect information about you from other sources including:  </p>

    <ul>
        <li>fraud prevention agencies, Know Your Customer and Anti Money Laundering service providers. We collect information to verify your identity and whether you are a Politically Exposed Person or included on anti money laundering watchlists from these sources.</li>
        <li>credit reference agencies. We collect your credit score, affordability assessments and other information relating to your financial history and creditworthiness from credit reference agencies such as Transunion.</li>
        <li>your nominated bank account. We collect details of your monthly balances, income and expenditure from your nominated bank account. We obtain this information via the Open Banking Data Services provided by Bud Financial Limited. In order to do this, we require you to link your UK bank accounts using your mobile banking app(s).</li>
    </ul>



<h3>Other information we use about you </h3>
<p>
If you decide to take out an optional Boost loan, we will conduct an affordability assessment about you. This involves gathering details from your nominated bank account regarding your monthly balances, income and expenditure. We will use Open Banking Data services provided by Bud Financial Limited, which will require you to link your bank accounts using your mobile banking app(s).
</p>
<h3>Special categories of personal data</h3>
<p>
Some of the categories of information that we collect are special categories of personal data (also known as sensitive personal information). This might include, but is not limited to, information about your personal wellbeing and circumstances as it relates to your ongoing suitability for the services we provide.
</p>

<h3>How we use your personal information and the basis on which we use it</h3>
<p>We use your personal information to: </p>

    <ul>
        <li>confirm your identity when you sign up or contact us.     </li>
        <li>consider your Boost loan application and make responsible lending decisions.     </li>
        <li>provide, improve and tailor our services in line with our terms and conditions. </li>
        <li>develop new platform products and services. </li>
        <li>deal with your enquiries and requests.</li>
        <li>comply with legal obligations to which we are subject and cooperate with regulators and law enforcement bodies.</li>
        <li>contact you with marketing and offers relating to platform products and services offered by us (unless you have opted out of marketing, or we are otherwise prevented by law from doing so).</li>
        <li>personalise the marketing messages we send you to make them more relevant, useful and interesting.</li>
        <li>exercise our rights under contracts we've entered into with you, like managing, collecting and recovering money you owe us.</li>
        <li>investigate and resolve complaints and other issues.</li>
        <li>keep your data safe and to improve features on the Belong app.</li>
    </ul>


<p>We must have a legal basis to process your personal information. In most cases, the legal basis will be one of the following: </p>

    <ul>
        <li>to fulfil a contract between us, or to take the steps necessary to enable us to enter into a contract that you have requested; for example, carrying out affordability assessments in the context of Boost loan applications.</li>
        <li>to comply with our legal obligations; for example, obtaining proof of your identity to enable us to meet our anti-money laundering obligations.</li>
        <li>to ensure the eligibility criteria of our platform products and services is met.</li>
        <li>to meet our legitimate interests, for example to understand how you use our services and use that to develop new services. When we process personal information to meet our legitimate interests, we put in place robust safeguards to ensure that your privacy is protected, and to ensure that our legitimate interests are not overridden by your interests or fundamental rights and freedoms.</li>
    </ul>


<p>
We may obtain your consent to collect and use certain types of personal information when we are required to do so by law (for example, in relation to our direct marketing activities, or when we process sensitive personal information). If we ask for your consent to process your personal information, you may withdraw your consent at any time by contacting us using the details at the end of this privacy notice. 
</p>

<h3>Your rights over your personal information </h3>
<p>You have certain rights regarding your personal information, subject to governing law. These include rights to:</p>

    <ul>
        <li>access your personal information.</li>
        <li>rectify the information we hold about you.</li>
        <li>erase your personal information.</li>
        <li>restrict our use of your personal information. </li>
        <li>object to our use of your personal information.</li>
        <li>receive your personal information in a usable electronic format and transmit it to a third party (right to data portability).</li>
        <li>lodge a complaint with your local data protection authority. In the UK this is the Information Commissioner's Office.</li>
    </ul>


<p>
We encourage you to contact us to update or correct your information if it changes or if the personal information we hold about you is inaccurate. 
</p>
<p>
We will contact you if we need additional information from you in order to honour your requests. 
</p>
<p>
If you would like to discuss or exercise such rights, please contact us at the details below:
</p>

    <ul>
        <li>In-app chat</li>
        <li>email to <a href="mailto:data@be-long.co">data@avione.io</a></li>
        <li>write to Avione Saving & Investment Ltd. Camburgh House, 27 New Dover Road, Canterbury, CT1 3DN</li>
    </ul>



<h3>Automated decisions about you</h3>
<p>
The way we analyse personal information for the purposes of, for example, risk assessment or fraud prevention, may involve profiling. This means that we may process your personal information using software that is able to evaluate your personal aspects and predict risks or outcomes. We may also use profiling, or solely automated means, to make decisions about you that relate to your use of the services and that have a legal (or similarly significant) effect on you. This is known as "automated decision-making" and it is only permitted when we have a legal basis for this type of decision-making.
</p>
<p>
We may make automated decisions about you: </p>


    <ul>
        <li>where such decisions are necessary for entering into a contract. For example, we may decide not to offer an optional Boost loan to you, based on your credit history and other financial information we have collected about you.</li>
        <li>where such decisions are required or authorised by law, for example for fraud prevention purposes.</li>
        <li>where you give your explicit consent to us carrying out automated decision-making.</li>
    </ul>


<p>
Subject to applicable legal requirements and limitations, you can contact us to request further information about automated decision-making, object to our use of automated decision-making, or request an automated decision to be reviewed by a human being. 
</p>
<p>
We may make automated decisions, which have a legal (or similar) effect on you based on your personal information in the following circumstances:
</p>

    <ul>
        <li>when performing KYC checks.</li>
        <li>when making decisions about affordability, creditworthiness or lending, in the case that you have opted to apply for a Boost loan.</li>
    </ul>


<h3>Information Sharing</h3>
<p>
We may share your personal information with third parties under the following circumstances:
</p>

    <ul>
        <li>KYC service providers. We may share your details with KYC service providers, such as Onfido, that help us with identity verification or fraud checks.</li>
        <li>Credit reference agencies. In the case that you have opted to apply for a Boost loan, we may share your details with credit reference agencies, such as TransUnion,  that help us make responsible lending decisions. More information about TransUnion and the ways in which it uses and shares personal information can be found in its privacy notice at https://www.transunion.co.uk/legal/privacy-centre.</li>
        <li>Lenders. We may share your details with lenders where you indicate that you would like to obtain a Boost loan. </li>
        <li>Custodians. We may share your details with a third party custodian in order to provide custody services in respect of your investments. </li>
        <li>Payment services providers. We may share your details with payment services providers to make payments to you and receive payments from you. </li>
        <li>Service providers and business partners. We may share your personal information with our service providers and business partners that perform marketing services and other business operations for us. For example, we may partner with other companies to process secure payments, fulfil orders, optimise our services, send newsletters and marketing emails, support email and messaging services and analyse information.</li>
        <li>Law enforcement agencies, court, regulator, government authority or other third party. We may share your personal information with these parties where we believe this is necessary to comply with a legal or regulatory obligation, or otherwise to protect our rights or the rights of any third party.</li>
        <li>Fraud prevention agencies (FPAs). When you apply for an account, we check your record with fraud prevention agencies such as CIFAS. During the application process and after you become a customer, we may share information about you with them to help prevent fraud and money laundering. If we detect fraud, we may stop activity on your account or block access. </li>
        <li>Asset purchasers. We may share your personal information with any third party that purchases, or to which we transfer, all or substantially all of our assets and business. Should such a sale or transfer occur, we will use reasonable efforts to try to ensure that the entity to which we transfer your personal information uses it in a manner that is consistent with this Privacy Policy. </li>
        <li>Anyone you give us permission to share it with.</li>
    </ul>


<p>
Because we operate as part of a global business, the recipients referred to above may be located outside the jurisdiction in which you are located (or in which we provide the services). See the section on "International Data Transfer" below for more information.
</p>
<h3>Information Security and Storage</h3>
<p>
We implement technical and organisational measures to ensure a level of security appropriate for the risk to the personal information we process. These measures are aimed at ensuring the on-going integrity and confidentiality of personal information. We evaluate these measures on a regular basis to ensure the security of the processing. 
</p>
<p>
We will keep your personal information for as long as we have a relationship with you. Once our relationship with you has come to an end, we will retain your personal information for a period of time that enables us to:
</p>

    <ul>
        <li>maintain business records for analysis and/or audit purposes.</li>
        <li>comply with record retention requirements under the law.</li>
        <li>defend or bring any existing or potential legal claims.</li>
        <li>deal with any complaints regarding the services.</li>
    </ul>


<p>
We will delete your personal information when it is no longer required for these purposes. If there is any information that we are unable, for technical reasons, to delete entirely from our systems, we will put in place appropriate measures to prevent any further processing or use of the data.
</p>
<p>
For further information about how we retain and use your data, please contact us via the contact details below.
</p>

<h3>International Data Transfer</h3>
<p>
Your personal information may be transferred to, stored, and processed in a country that is not regarded as ensuring an adequate level of protection for personal information under UK law. 
</p>
<p>
We put in place appropriate safeguards (such as contractual commitments) in accordance with applicable legal requirements to ensure that your data is adequately protected. For more information on the appropriate safeguards in place, please contact us at the details below. 
</p>
<h3>Changes to the Policy</h3>
<p>
You may request a copy of this privacy notice from us using the contact details set out above. We may modify or update this privacy notice from time to time. 
</p>
<p>
If we change this privacy notice, we will notify you of the changes. Where changes to this privacy notice will have a fundamental impact on the nature of the processing or otherwise have a substantial impact on you, we will give you sufficient advance notice so that you have the opportunity to exercise your rights (e.g. to object to the processing).
</p>


    </div>
    <Footer />
  </div>
);

export { Privacy };