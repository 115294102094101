import React, {
  createContext,
  useContext,
  ReactNode,
  useRef,
  MutableRefObject,
} from "react";

const BackgroundOffsetContext = createContext<MutableRefObject<number> | null>(
  null
);

export const useBackgroundOffset = () => useContext(BackgroundOffsetContext);

export const BackgroundOffsetProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const scrollPositionRef = useRef(0);

  return (
    <BackgroundOffsetContext.Provider value={scrollPositionRef}>
      {children}
    </BackgroundOffsetContext.Provider>
  );
};
