import "./Home.css";

const Home = () => (
  <div className="App-container">
    <header className="App-header">
      <div>
        <img
          src="https://avione-public-assets-prod.s3.eu-west-2.amazonaws.com/belong-logo-transparent-bg.png"
          alt="logo"
          className="Home-logo"
        />
        <p className="Home-text">MAKING WEALTH A CHOICE</p>
      </div>
      <div className="Home-small-print">
        <p>Avione Saving &amp; Investment Limited</p>
        <p>Registered Company: 12873469</p>
        <p>5 New Street Square, London EC4A 3TW</p>
      </div>
    </header>
  </div>
);

export { Home };
