import Arrow from "../../../components/svg/Arrow";
import { InputValidation } from "../types";
import loadingStyles from "./../../../components/button/Loading.module.css";
import styles from "./../styles/referrals.module.css";
import { Toggle } from "./toggle/Toggle";

interface Props {
  onTextChange: React.ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean;
  onToggle?: (value: boolean) => void;
  toggleState?: boolean;
  errors?: InputValidation;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

export function ReferralsFormNew(props: Props) {
  return (
    <form className={styles.formContainer} onSubmit={props.onSubmit}>
      <div className={styles.referralInputContainer}>
        {/* <div
          style={{
            paddingLeft: "5%",
            flexGrow: 1,
            // backgroundColor: "blue",
            overflow: "hidden",
            // height: "100%",
          }}
        > */}
        <input
          id="email"
          type={"email"}
          name="email"
          className={styles.referralInput}
          placeholder="Your email address"
          onChange={props.onTextChange}
        ></input>{" "}
        <button
          className={styles.referralInputButton}
          disabled={!props.toggleState}
          style={{
            backgroundColor: !props.toggleState ? "#bf9577" : undefined,
          }}
        >
          {props.isLoading ? (
            <div className={loadingStyles.ldsRing}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <Arrow />
          )}
        </button>
      </div>
      {/* </div> */}
      <div className={styles.checkboxDiv}>
        {/* <input
          type="checkbox"
          checked={props.toggleState}
          onChange={() =>
            props.toggleState !== undefined &&
            props.onToggle !== undefined &&
            props.onToggle(!props.toggleState)
          }
          style={{
            accentColor: props.toggleState ? "#cb926a" : "transparent",
            cursor: "pointer",
          }}
        /> */}
        {/* <Toggle
          state={props.toggleState}
          onToggle={() =>
            props.toggleState !== undefined &&
            props.onToggle !== undefined &&
            props.onToggle(!props.toggleState)
          }
        />
        <label className={styles.checkboxDivLabel}
          onClick={() =>
            props.toggleState !== undefined &&
            props.onToggle !== undefined &&
            props.onToggle(!props.toggleState)
          }
        >
          Keep me updated about Belong
        </label> */}
      </div>
      {props.errors?.email ? <p>{props.errors.email}</p> : null}
    </form>
  );
}
