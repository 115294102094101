import { ReferrerResponse } from "./types";

export const getBaseUrl = () => {
  // const environment = process.env.NODE_ENV || "development";

  // let baseURL: string;

  // if (environment === "production") {
  //   baseURL = process.env.REACT_APP_PROD_REFERRAL_BASE_URL || "";
  // } else {
  //   baseURL = process.env.REACT_APP_DEV_REFERRAL_BASE_URL || "";
  // }

  // return baseURL;
  return 'https://0pxnsgnahj.execute-api.eu-west-2.amazonaws.com/belong/referrals';
};

export const getFormValues = (formEvent: React.FormEvent<HTMLFormElement>) => {
  const formData = new FormData(formEvent.currentTarget);
  const referralCode = formData.get("code");
  const email = formData.get("email");
  const mobile = formData.get("mobile");
  const forename = formData.get("forename");
  const surname = formData.get("surname");
  return {
    referralCode,
    email,
    mobile,
    forename,
    surname,
  };
};

export const fetchReferralInfo = async (
  {
    email,
    // mobile,
    // referralCode,
    forename,
    surname,
  }: {
    // referralCode: FormDataEntryValue | null;
    email: FormDataEntryValue | null;
    // mobile: FormDataEntryValue | null;
    forename: FormDataEntryValue | null;
    surname: FormDataEntryValue | null;
  },
  referralId: string
) => {
  const baseUrl = getBaseUrl();
  // console.log({baseUrl});
  const queryParams = `?forename=${forename}&surname=${surname}&email=${email}&referralId=${referralId}`;
  const fullUrl = `${baseUrl}${queryParams}`;

  const response = (await fetch(fullUrl).then((res) =>
    res.json()
  )) as unknown as ReferrerResponse;

  return response;
};
