import * as React from "react";
function Arrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8356_18554)">
        <path
          d="M17.4076 18.6129C16.4673 19.3698 15.7126 20.2226 15.1616 21.1444C14.2256 22.7119 14.1941 23.9792 14.1938 23.9917C14.1938 23.9786 14.1938 23.9718 14.1938 23.9718L10.9029 23.999C10.9012 23.7974 10.9226 21.9651 12.1935 19.7163C12.9271 18.4184 13.9344 17.2351 15.1868 16.1997C16.3935 15.2012 17.8349 14.34 19.4806 13.6296L0.999999 13.6296L0.999999 10.3684L19.4806 10.3684C17.8352 9.65805 16.3946 8.79683 15.1868 7.79837C13.9344 6.76295 12.9271 5.57969 12.1935 4.28148C10.9226 2.03399 10.9001 0.201491 10.9029 0.000113575L14.1938 0.0272911C14.1938 0.0272911 14.1938 0.020768 14.1938 0.0077217C14.1938 0.0202244 14.2256 1.28719 15.1616 2.85499C15.7126 3.77899 16.4684 4.62961 17.4076 5.3862C18.6143 6.35884 20.1325 7.17984 21.9178 7.82337L23 8.2139L23 15.7852L21.9178 16.176C20.1325 16.8204 18.6151 17.6408 17.4076 18.6129Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8356_18554">
          <rect
            width={24}
            height={24}
            fill="white"
            transform="translate(24 24) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default Arrow;
