import { Header } from "./Header";
import { Footer } from "./Footer";

const Support = () => (
  <div className="App">
    <Header text="Support for the Belong App" />
      <div className="content-container">
      <h3>Contact Us</h3>
      <p>
The best way to contact us is using the Belong in-app chat, or you can email us at <a href="mailto:support@be-long.co">support@be-long.co</a>. You can also write to us Belong, Avione Saving and Investment Ltd, Head of Compliance, Camburgh House, 27 New Dover Road, Canterbury, CT1 3DN.
</p>
<p>
Belong is a trading name for Avione Saving & Investment Ltd, company number 12873469. Belong is an appointed representative of RiskSave Technologies Ltd (FRN 775330) for all regulated investment activity and Creative Finance Corp Ltd (FRN 702435) for all regulated credit activity. For more information about our regulated activites, please read our Privacy Policy which can be <a href="/privacy">found here</a>.
</p>
<h3>How to complain to Belong</h3>
<p>
If you have a complaint about the service you 've received, please contatct us through the in-app chat and we will try our best to resolve it for you. If that doesn't work, you can:
</p>

  <ul>
    <li>email us at <a href="mailto:feedback@be-long.co">feedback@be-long.co</a></li>
    <li>write to us at Belong, Avione Saving and Investment Ltd, Head of Compliance, Camburgh House, 27 New Dover Road, Canterbury, CT1 3DN.</li>
  </ul>

<p>
We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy. If you're still not happy, you can refer your complaint to the UK's supervisory authority: the Information Commissioner's Office (ICO). For more details, you can visit their website at ico.org.uk. We are registered with the ICO, registration no. ZB189039.
</p>
      </div>
      <Footer />
  </div>
);

export { Support };