import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, NotFound, OpenBanking, Support, Privacy, AppLinks } from "./pages";
import { Referrals } from "./pages/referrals/Referrals";
import "./App.css";
import JoinBelongNow from "./pages/referrals/JoinBelongNow";
import { AnswersReceived } from "./pages/referrals/AnswersReceived";
// import { Helmet } from "react-helmet";
import { BackgroundOffsetProvider } from "./components/scrollContext/BackgroundOffsetContext";

const App = () => (
  <BackgroundOffsetProvider>
    <Router>
      {/* <Helmet>
      <title>Belong</title>
      <meta
        name="description"
        content="Belong is a personal finance platform for people who are ready to
            play the long game."
      />
      <meta
        name="keywords"
        content="belong, invest, investment, long, indexfund, index, wealth, growth"
      />
      <meta property="og:title" content="Belong" />
      <meta
        property="og:description"
        content="Belong is a personal finance platform for people who are ready to
            play the long game."
      />
      <meta
        property="og:image"
        content="https://avione-client-documentation-app.s3.eu-west-2.amazonaws.com/Belong_Logotype_Negative-Black-And-Off-White.png"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </Helmet> */}
      <div>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/payment" exact component={OpenBanking} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/support" exact component={Support} />
          <Route path="/referrals" exact component={Referrals} />
          <Route path="/referrals/join" exact component={JoinBelongNow} />
          <Route path="/referrals/confirmed" exact component={AnswersReceived} />
          <Route path="/applinks*" component={AppLinks} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  </BackgroundOffsetProvider>
);

export default App;
