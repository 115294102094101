import "./Footer.css";

const Footer = () => (
    <div className="footer-container">
        <p>Avione Saving &amp; Investment Limited</p>
        <p>Registered Company: 12873469</p>
        <p>5 New Street Square, London EC4A 3TW</p>
      </div>
)

export { Footer }