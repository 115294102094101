import styles from "./styles/referrals.module.css";
import React, { useEffect, useState } from "react";
import useReferralsParallax from "./hooks/useReferralsParallax";
import CrossHair from "../../components/svg/CrossHair";
import Arrow from "../../components/svg/Arrow";
import BelongLogo from "../../components/svg/BelongLogo";
import { useHistory, useLocation } from "react-router-dom";
import { InputValidation } from "./types";
import { fetchReferralInfo, getFormValues } from "./functions";
import loadingStyles from "./../../components/button/Loading.module.css";
import { ReferralsStandard } from "./ReferralsStandard";
import { ReferralsFormNew } from "./components/ReferralsFormNew";

const Referrals = () => {
  const query = useQuery();
  const referralId = query.get("referralId");
  const [isLoading, setIsLoading] = useState(false);
  const [accepted, setAccepted] = useState(true);

  const [errors, setErrors] = useState<InputValidation>({
    email: "",
    referralId: "",
  });
  // const { storeBackgroundOffset } = useReferralsParallax(!!referralId);
  const history = useHistory();

  const handleFormSubmission: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    // storeBackgroundOffset();

    const formValues = getFormValues(e);
    setIsLoading(true);
    const response = await fetchReferralInfo(
      formValues,
      referralId ?? ""
    ).finally(() => setIsLoading(false));
    
    if (!response) {
      return;
    }
    const { inputValidation, outcome, message } = response;

    const redirectUrl = response.formUrl ?? "";
    // check errors
    setErrors(inputValidation);
    const hasError = Object.entries(inputValidation).some(
      (iv) => !!iv[1] && iv[0] !== "referralId"
    );

    if (outcome === "MESSAGE") {
      // show the messsage
      return;
    }

    if (hasError) {
      return;
    }

    // storeBackgroundOffset();
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }

    if (outcome === "JOIN_IMMEDIATELY") {
      history.push(`/referrals/join`);
      return;
    } else if (outcome === "NOT_ELIGIBLE") {
      history.push(`/referrals/confirmed`);
      return;
    }
  };

  const resetError = (fieldName: string) => {
    setErrors((e) => {
      return { ...e, [fieldName]: "" };
    });
  };

  const onTextChange = () => resetError && resetError("email");

  const onToggle = () => setAccepted((a) => !a);

  useEffect(()=>{

    if(!referralId) {
      window.location.href = 'https://be-long.co';
    }

  }, [referralId]);

  if (!referralId) {
    return <ReferralsStandard onSubmit={handleFormSubmission}
    onTextChange={onTextChange}
    errors={errors}
    isLoading={isLoading}
    onToggle={onToggle}
    toggleState={accepted} />;
  }

  return (
    <div
      className="App"
      id="app"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className={styles.logoContainer}>
        <BelongLogo width={150} height={150} />
      </div>
      <div id="background" className={styles.mainContentContainer}>
        <div className={styles.heroBackground}>
          <p className={styles.heroText}>
            Fortune
            <br />
            favours those who Belong
          </p>
        </div>
        <img
          className={styles.backgroundImage}
          id="backgroundImage"
          src="/background/large.jpg"
          alt="t"
        />
        <div className={styles.refCodeFormContainer}>
          <p className={styles.formWelcome}>
            Welcome to the Golden Ticket waitlist
          </p>
          <ReferralsFormNew
            onSubmit={handleFormSubmission}
            onTextChange={onTextChange}
            errors={errors}
            isLoading={isLoading}
            onToggle={onToggle}
            toggleState={accepted}
          />
        </div>
      </div>
      <div className={styles.disclaimerContainer}>
        <div className={styles.disclaimerCrossHair}>
          <CrossHair width={40} height={40} />
        </div>
        <div>
          <p>
            As with all investing, your money will be at risk. The value of your
            investment may go down as well as up and you could get back less
            than you put in.
          </p>
          <p>
            Avione Saving & Investment Ltd (FRN 971870) (trading as Belong) is
            an appointed representative of RiskSave Technologies, which is
            authorised and regulated by the Financial Conduct Authority (FRN
            775330) Avione Saving & Investment Ltd is a registered company
            number 12873469. 5 New Street Square, London EC4A 3TW
          </p>
        </div>
      </div>
      {/* <div id="content" className={styles.content}></div> */}
    </div>
  );
};

export { Referrals };

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
