import * as React from "react";
function BelongLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      style={{
        enableBackground: "new 0 0 800 347.53",
      }}
      xmlSpace="preserve"
      viewBox="79.85 78.65 641.49 189.91"
      {...props}
    >
      <style type="text/css">
        {" \t.st0{fill:#FFFFFF;} \t.st1{fill:#EBE8E4;} "}
      </style>
      <g>
        <path d="M702.65,119.27l-2.82,18.81l-1.25-1.91c-3.65-5.61-8.49-10.1-14.38-13.32c-5.9-3.23-13.03-4.86-21.19-4.86  c-9.73,0-18.6,2.32-26.35,6.9c-7.75,4.58-13.93,11.12-18.37,19.43c-4.45,8.33-6.71,18.08-6.71,28.98c0,11.33,2.22,21.26,6.6,29.52  c4.36,8.24,10.5,14.67,18.25,19.1c7.75,4.44,16.69,6.69,26.58,6.69c15.6,0,27.5-5.98,35.36-17.76l1.59-2.38v11.04  c0,21.08-10.98,31.77-32.63,31.77c-8.34,0-15.26-1.76-20.57-5.22c-5.17-3.37-8.58-8.16-10.14-14.23h-22.14  c1.16,11.47,6.25,20.5,15.11,26.84c9.17,6.56,21.28,9.89,36.01,9.89c18.98,0,33.18-4.25,42.19-12.62  c8.99-8.36,13.55-20.33,13.55-35.56V119.27H702.65z M690.89,200.33L690.89,200.33c-6.05,6.86-14.12,10.34-23.99,10.34  c-9.87,0-17.94-3.52-23.99-10.45c-6.02-6.9-9.07-15.96-9.07-26.93c0-10.97,3.05-20.03,9.07-26.93  c6.05-6.94,14.12-10.45,23.99-10.45c9.87,0,17.94,3.55,23.99,10.56c6.02,6.98,9.07,16.14,9.07,27.25  C699.97,184.54,696.91,193.49,690.89,200.33z" />
        <path d="M460.6,124.99c-8.4-4.66-18.18-7.02-29.07-7.02c-10.89,0-20.67,2.36-29.07,7.02c-8.39,4.65-14.96,11.26-19.54,19.64  c-4.59,8.4-6.92,18.19-6.92,29.08c0,11.04,2.33,20.9,6.92,29.29c4.58,8.39,11.15,14.96,19.53,19.53c8.4,4.59,18.18,6.92,29.08,6.92  c10.89,0,20.68-2.33,29.08-6.92c8.38-4.58,14.95-11.15,19.53-19.53c4.59-8.41,6.92-18.26,6.92-29.29c0-10.89-2.33-20.67-6.92-29.08  C475.55,136.25,468.98,129.64,460.6,124.99z M455.84,200.75L455.84,200.75c-6.12,7.01-14.3,10.56-24.31,10.56  c-10.01,0-18.16-3.55-24.21-10.56c-6.02-6.97-9.07-16.06-9.07-27.04c0-10.97,3.05-20.06,9.07-27.03  c6.05-7.01,14.2-10.56,24.21-10.56c10.01,0,18.19,3.55,24.31,10.56c6.09,6.98,9.18,16.07,9.18,27.04  C465.02,184.68,461.93,193.78,455.84,200.75z" />
        <path d="M160.76,118.02c-10.83,0-19.55,2.66-25.91,7.91c-4.84,3.99-8.49,8.45-10.9,13.29c0.7-5.84,1.05-10.78,1.05-14.73v-7.27  c0-6.23,0.08-13.58,0.24-21.85c0.16-8.69,0.24-12.83,0.24-13.27c0-2.03-1.23-3.24-3.29-3.24H79.85v8.48l0.95,0.14  c8.38,1.25,14,3.31,16.71,6.13c2.68,2.8,4.04,7.84,4.04,14.99v67.6c0,17.73-0.49,35.9-1.45,54.02l-0.06,1.14h10.53l0.31-0.59  c1.38-2.59,3.75-6.24,7.06-10.83h1.13c8.67,8.15,20.66,12.28,35.65,12.28c17.79,0,31.78-5.82,41.6-17.3  c9.77-11.42,14.72-25.32,14.72-41.31c0-15.4-4.42-28.64-13.14-39.35C189.14,123.49,176.64,118.02,160.76,118.02z M178,207.86  c-5.05,8.41-12.51,12.5-22.78,12.5c-10.51,0-18.36-3.23-23.34-9.59c-5.05-6.45-7.61-14.46-7.61-23.8v-12.5  c0-13.48,3.09-24.24,9.17-31.98c6.02-7.65,13.75-11.53,22.98-11.53c9.64,0,16.75,3.68,21.72,11.24c5,7.62,7.54,18.48,7.54,32.28  C185.68,188.14,183.1,199.38,178,207.86z" />
        <path d="M278.38,118.02c-16.82,0-30.29,5.68-40.03,16.87c-9.69,11.14-14.61,24.82-14.61,40.67c0,16.86,4.76,30.65,14.14,41.01  c9.42,10.39,23.69,15.67,42.42,15.67c15.19,0,26.97-4.06,35.03-12.07c8.02-7.97,12.66-17.21,13.81-27.46l0.13-1.2h-11.59l-0.16,0.9  c-1.39,8.03-5.12,14.55-11.07,19.39c-5.95,4.84-13.54,7.29-22.55,7.29c-23.14,0-34.53-14.45-34.8-44.16h81v-6.25  c0-14.58-4.77-26.8-14.17-36.3C306.53,122.84,293.89,118.02,278.38,118.02z M249.71,163.48c2.25-22.89,11.4-34.03,27.95-34.03  c15.89,0,24.27,11.14,25.59,34.03H249.71z" />
        <path d="M556.41,116.59c-14.92,0-26.61,5.32-34.76,15.82l-1.19,1.54l-2.8-16.03h-18.72v111.54h21.6v-56.21  c0-11.67,2.72-20.98,8.08-27.67c5.41-6.75,12.94-10.17,22.39-10.17c8.87,0,15.71,2.94,20.33,8.74c4.57,5.75,6.89,14.2,6.89,25.13  v60.19h21.82v-61.52c0-17.96-4.09-31.15-12.16-39.2C579.81,120.68,569.22,116.59,556.41,116.59z" />
        <rect x={342.51} y={78.65} width={21.6} height={150.81} />
      </g>
    </svg>
  );
}
export default BelongLogo;
