import "./Header.css";

const Header = ({text=''}) => (
    <header className="header-container">
      {/* <div onClick={()=>{ window.open('https://be-long.co'); }} style={{cursor:'pointer'}}> */}
        <a href="https://be-long.co"><img src="https://avione-public-assets-prod.s3.eu-west-2.amazonaws.com/belong-logo-transparent-bg.png" alt="logo" className="header-logo" /></a>
        <p className="header-text">{ text }</p>
      {/* </div> */}
    </header>
)

export { Header }