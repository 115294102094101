import "./../Home.css";
import { ReferralsFormNew } from "./components/ReferralsFormNew";
import { Header } from '../Header';
import { Footer } from '../Footer';

function ReferralsStandard(props:any) {
  return (
    <div className="App">
      <Header text="Fortune favours those who belong" />
        <div className="content-container">
          <div style={{margin:'0 auto'}}>
            <ReferralsFormNew {...props} />
          </div>
        </div>
      <Footer />
    </div>
  );
}

function ReferralsStandardOLD() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img
            src="https://avione-public-assets-prod.s3.eu-west-2.amazonaws.com/belong-logo-transparent-bg.png"
            alt="logo"
            className="Home-logo"
          />
          <p className="Home-text">Fortune favours those who belong</p>
        </div>
        <div>
          {/* <ReferralsFormNew /> */}
        </div>
        <div className="Home-small-print">
          <p>Avione Saving &amp; Investment Limited</p>
          <p>Registered Company: 12873469</p>
          <p>5 New Street Square, London EC4A 3TW</p>
        </div>
      </header>
    </div>
  );
}

export { ReferralsStandard };
