import styles from "./styles/referrals.module.css";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import useReferralsParallax from "./hooks/useReferralsParallax";
import CrossHair from "../../components/svg/CrossHair";
import Arrow from "../../components/svg/Arrow";
import BelongLogo from "../../components/svg/BelongLogo";
import { useHistory, useLocation } from "react-router-dom";
import { InputValidation } from "./types";
import { fetchReferralInfo, getFormValues } from "./functions";
import loadingStyles from "./../../components/button/Loading.module.css";
import { DownloadFromAppleAppStore } from "./components/DownloadFromAppleAppStore";
import { CSSTransition } from "react-transition-group";
import { BELONG_WEBSITE_URL } from "../../config/constants";

const JoinBelongNow = () => {
  const nodeRef = useRef(null);

  useReferralsParallax();
  const [errors, setErrors] = useState<InputValidation>({
    email: "",
    referralId: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const resetError = (fieldName: string) => {
    setErrors((e) => {
      return { ...e, [fieldName]: "" };
    });
  };

  return (
    <div
      className="App"
      id="app"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className={styles.logoContainer}>
        <BelongLogo width={150} height={150} />
      </div>
      <div id="background" className={styles.mainContentContainer}>
        <div className={styles.heroBackground}>
          <p className={styles.heroText}>
            Fortune
            <br />
            favours those who Belong
          </p>
        </div>
        <img
          className={styles.backgroundImage}
          id="backgroundImage"
          src="/background/large.jpg"
          alt="t"
        />
        <CSSTransition
          in={true}
          timeout={1000}
          classNames="fade"
          nodeRef={nodeRef}
          appear
        >
          <div className={styles.joinNowContainer} ref={nodeRef}>
            <h1 className={styles.title}>You've been bumped up the waitlist</h1>
            <p>
              With a golden ticket, you're at the front of the line. You can
              download the app and start investing whenever you’re ready.
            </p>
            <div className="appStoreLinksContainer">
              <button
                style={{
                  backgroundColor: "transparent",
                  outline: "none",
                  borderColor: "transparent",
                  padding: "0px",
                  cursor: "pointer",
                }}
              >
                <DownloadFromAppleAppStore width={200} height={100} />
              </button>
            </div>
            <p>
              FYI - you may need physical photo ID during the process, so have
              this handy just in case. If you’d like to open an ISA, you’ll also
              need your National Insurance number. (Don’t worry if now’s not a
              good time - we’ll also email you the details.)
            </p>
            <p>
              Want more info about Belong, deciding how much to invest, applying
              for a Boost loan, and choosing a fund?{" "}
              <a style={{ color: "inherit" }} href={BELONG_WEBSITE_URL}>
                Click here to learn more about Belong.
              </a>
            </p>
          </div>
        </CSSTransition>
      </div>
      <div className={styles.disclaimerContainer}>
        <CrossHair width={80} height={40} />
        <p>
          As with all investing, your money will be at risk. The value of your
          investment may go down as well as up and you could get back less than
          you put in.
        </p>
      </div>
      {/* <div id="content" className={styles.content}></div> */}
    </div>
  );
};

export default JoinBelongNow;
