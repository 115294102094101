import styles from "./styles/referrals.module.css";
import React, { useEffect, useState } from "react";
import useReferralsParallax from "./hooks/useReferralsParallax";
import CrossHair from "../../components/svg/CrossHair";
import Arrow from "../../components/svg/Arrow";
import BelongLogo from "../../components/svg/BelongLogo";
import { useHistory, useLocation } from "react-router-dom";
import { InputValidation } from "./types";
import { fetchReferralInfo, getFormValues } from "./functions";
import loadingStyles from "./../../components/button/Loading.module.css";
import { DownloadFromAppleAppStore } from "./components/DownloadFromAppleAppStore";
import { BELONG_WEBSITE_URL } from "../../config/constants";
import { FollowInstaText } from "../../components/text/FollowInstaText";

const AnswersReceived = () => {
  useEffect(() => {
    const vh = window.innerHeight / 100;
    window.scrollTo({ top: 100 * vh });
  });

  useReferralsParallax();

  const [errors, setErrors] = useState<InputValidation>({
    email: "",
    referralId: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const resetError = (fieldName: string) => {
    setErrors((e) => {
      return { ...e, [fieldName]: "" };
    });
  };

  return (
    <div
      className="App"
      id="app"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className={styles.logoContainer}>
        <BelongLogo width={150} height={150} />
      </div>
      <div id="background" className={styles.mainContentContainer}>
        <div className={styles.heroBackground}>
          <p className={styles.heroText}>
            Fortune
            <br />
            favours those who Belong
          </p>
        </div>
        <img
          className={styles.backgroundImage}
          id="backgroundImage"
          src="/background/large.jpg"
          alt="t"
        />

        <div className={styles.joinNowContainer}>
          <h1 className={styles.title}>
            Looks like you’re already on the waitlist!
          </h1>
          <p>
            To learn more about Belong in the meantime,{" "}
            <a href={BELONG_WEBSITE_URL} style={{ color: "inherit" }}>
              click here
            </a>
            .
          </p>
          <FollowInstaText />
        </div>
      </div>
      <div className={styles.disclaimerContainer}>
        <CrossHair width={80} height={40} />
        <p>
          As with all investing, your money will be at risk. The value of your
          investment may go down as well as up and you could get back less than
          you put in.
        </p>
      </div>
      {/* <div id="content" className={styles.content}></div> */}
    </div>
  );
};

export { AnswersReceived };
