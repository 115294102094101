import { Header } from "./Header";
import { Footer } from "./Footer";

const AppLinks = () => {
    return (
        <div className="App">
            <Header text="" />
            <div className="content-container">
                <h3>Please open your Belong App to see the payment</h3>
                {/* <p>AppLinks</p> */}
            </div>
            <Footer />
        </div>
    );
    }

export { AppLinks }